import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { computed } from "vue";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";


const useLandings = () =>{

    const store = useStore();
    const route = useRoute()
    const toast  = useToast();
    const { t } = useI18n()

    const getLandings = async(page, filters)=>{

        const response = await store.dispatch('landings/getLandings', {page:page, filters:filters})

        return response
    }

  const getCategories = async() => {

    const response = await store.dispatch('landings/getCategories')

    return response
  }
    const getLanding = async(id)=>{

        const response = await store.dispatch('landings/getLanding',id)

        return response
    }

    const cloneLanding = async(landingId) =>{
        const response = await store.dispatch('landings/cloneLanding', landingId)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const updateLanding = async(landing, landingId) =>{
        landing.append('_method', 'PUT')
        const response = await store.dispatch('landings/updateLanding', {formData: landing, id: landingId})
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const storeLanding = async(landing) =>{
        const response = await store.dispatch('landings/storeLanding', landing)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const deleteLanding = async(landingId) =>{
        const response = await store.dispatch('landings/deleteLanding', landingId)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const getPreview = async(landing) =>{
        const response = await store.dispatch('landings/getPreviewLanding', landing);
        return response
    }
    const checkUniqueUrl = async(url) =>{
        const response = await store.dispatch('landings/checkUniqueUrl', url);
        return response
    }
    const getPublicLanding = async(url) =>{
        const response = await store.dispatch('landings/getPublicLanding', url);
        return response
    }
    const getLandingsTemplates = async(page, filter)=>{

      const response = await store.dispatch('landings/getLandingsTemplates', {page:page, filter:filter})

      return response
    }
    const cloneLandingTemplate = async(landingId) =>{
      const response = await store.dispatch('landings/cloneLandingTemplate', landingId)
      if(response && response.status === 200){
        toast.success(t("notification.success.general"));
      }
      return response
    }

    return{

        landingsData: computed(()=> store.state.landings.landingsData),
        landingsTemplatesData: computed(()=> store.state.landings.landingsTemplatesData),
        getLandings,
        cloneLanding,
        updateLanding,
        storeLanding,
        deleteLanding,
        getPreview,
        getLanding,
        checkUniqueUrl,
        getPublicLanding,
        getLandingsTemplates,
        cloneLandingTemplate,
        getCategories,
        categories: computed(() => store.state.landings.categories)
    }
}
export default useLandings;
